import React, {useEffect} from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import RowWrapper from '../utils/rowWrapper';

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';

import Header from "../components/header-section";
import ImageSection from '../components/image-section';

import Contact from '../components/contact-button';

gsap.registerPlugin(ScrollTrigger);

const Main = styled.div`

margin-top: 8rem;

h1 {
  font-size: 6rem;
  color: ${props => props.theme.colors.color};
  text-align: center;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 3rem;
  }
}

@media ${props => props.theme.breaks.down('sm')} {
  margin-top: 4.5rem;
}

`

const Points = styled.div`
  margin-top: 8rem;
`

const Point = styled.h2`
  font-size: 4rem;
  color: ${props => props.theme.colors.color};
  text-align: center;
  margin-bottom: 8rem;

  &.no-margin {
    margin-bottom: 1rem;
  }

  &.black {
    color: ${props => props.theme.colors.text};
  }

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2.5rem;
    margin-bottom: 6rem;
  }

`

const Sub = styled.span`
  font-size: 2.5rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
  }
`

const Paragraph = styled.p`
  width: 70%;
  max-width: 100rem;
  margin: 0 auto 7rem auto;
  text-align: center;
  font-size: 2.6rem;
  line-height: 3.2rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
    width: 90%;
  }
`

const Footer = styled.div`
  background-color: ${props => props.theme.colors.color};
  margin-top: 10rem;
  padding: 10rem;

  h2 {
    color: ${props => props.theme.colors.body};
    font-size: 5rem;
    text-align: center;
    margin-bottom: 3rem;
  }

  p {
    color: ${props => props.theme.colors.body};
    text-transform: uppercase;
    font-size: 2.5rem;
    text-align: center;
    width: 60%;
    line-height: 3.5rem;
    letter-spacing: -1px;
    margin: 0 auto;
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 5rem;
    padding: 3rem 2rem;
    h2 {
      font-size: 3rem;
    }
    p {
      font-size: 1.8rem;
      width: 100%;
    }
  }
  
`

// const PsFooter = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `

const Ps = styled.p`
  font-size: 2.5rem;
  text-align: center;
  margin: 3rem auto;
  flex-grow: 1;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 1.8rem;
    line-height: 2rem;
  }
`

 
const We = ({data}) => {

  useEffect(() => {
    let pointers = gsap.utils.toArray(".pointers-we");

    pointers.forEach(cur => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cur,
          start: "top 90%",
          toggleActions: "play none none reverse"
        }
      })

      tl.fromTo(cur, {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1.2})
    })

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".no-margin",
        start: "top 90%",
        toggleActions: "play none none reverse"
      }
    })

    tl2.fromTo(".no-margin", {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1})
    .fromTo(".black", {autoAlpha: 0, y: 100}, {autoAlpha: 1, y: 0, duration: 1, delay: .3})

  }, [])

  const image = getImage(data.file)
 
 return (
  <Layout pageTitle="Create A Space - We">
    <Header img={image} />
    <RowWrapper>
      
      <Main>
        <h1>Who Are We?</h1>

        <Points>
          <Point className="pointers-we">
            Hi, we’re Create A Space. <br />
            <Sub>(Nice to meet you.)</Sub>
          </Point>
          <Point className="pointers-we">
            We’re dreamers, designers, doers. <br />
            <Sub>(We make custom closets.)</Sub>
          </Point>
          <Point className="pointers-we">
            We’re artists, amazers, awe-inspirers. <br />
            <Sub>(We like to wow.)</Sub>
          </Point>
          <Point className="pointers-we">
            We’re creators, cooperators, closeteers. <br />
            <Sub>(We’re here to help.)</Sub>
          </Point>
        </Points>
        <Paragraph>
        We create gorgeous closets, custom-built for the homes they go in and for the people who use them. That means serving your storage and organization needs, whatever they may be. It also means building closets that seamlessly fit into your home, like they were always meant to be there (they were).
        </Paragraph>
        <Point className="no-margin">In short, we make</Point>
        <Point className="black">
          beautiful spaces for beautiful places. <br />
          <Sub>(How can we help?)</Sub>
        </Point>
        <ImageSection img={image} />
      </Main>
    </RowWrapper>
    <Footer>
      <RowWrapper>
        <h2>
        Mission Statement
        </h2>
        <p>
        We complete you. We never want you to feel like your home is missing something. We never want you to feel like your clothes are more beautiful than your closet. We want you to start your day with a wow.
        </p>
      </RowWrapper>
    </Footer>

    {/* <RowWrapper>
      <PsFooter> */}
        <Contact pos="-.5rem" left="6%" />
        <Ps>
          Serving the tri-state area of New York, New Jersey, and Connecticut.
        </Ps>
      {/* </PsFooter>
    </RowWrapper> */}
  </Layout>
 )
}

export default We;

export const query = graphql`
query WeQuery {
  file(relativePath: { eq: "header-we.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1800, height: 1000, quality: 100)
      }
    }
  }
`